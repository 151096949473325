<template>
  <div>
    <user-aside
      v-if="aside === 'user'"
      :active="true"
      :merchant="merchant"
      :resource="user"
      @changed="refresh"
      @aside="bol => { closeAside(bol); !bol && refresh() } "
    />
    <v-card class="mb-2">
      <v-card-title>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="mb-4 me-3"
          @click="toggleAside(null, 'user')"
        >
          <v-icon>{{ icons.mdiPlus }}</v-icon>
          <span>Add New User</span>
        </v-btn>
      </v-card-title>

      <v-data-table
        :headers="tableColumnHeaders"
        :items="users"
        :sort-by="['id']"
        :sort-desc="[true]"
        hide-default-footer
      >
        <!-- id -->
        <template #[`item.id`]="{item}">
          <div class="d-flex align-center">
            {{ item.id }}
          </div>
        </template>

        <template #[`item.profile.name`]="{item}">
          <div class="d-flex align-center">
            {{ item.profile.name }}
          </div>
        </template>

        <template #[`item.email`]="{item}">
          <div class="d-flex align-center">
            {{ item.email }}
          </div>
        </template>

        <template #[`item.created_at`]="{item}">
          <div class="d-flex align-center">
            {{ item.created_at | date }}
          </div>
        </template>

        <template #[`item.deleted_at`]="{item}">
          <div class="d-flex align-center">
            <v-chip
              v-if="item.deleted_at"
              class="ma-2"
              color="error"
              text-color="white"
            >
              Yes
            </v-chip>
          </div>
        </template>

        <!-- Action -->
        <template #[`item.action`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list v-if="item.deleted_at == null">
              <v-list-item
                link
                @click="toggleAside(item, 'user')"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiPencil }}
                  </v-icon>
                  <span>Edit</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="item.deleted_at == null"
                link
                @click="destroy(item)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Delete</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
            <v-list v-else>
              <v-list-item
                link
                @click="restore(item)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiRestore }}
                  </v-icon>
                  <span>Restore</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { date } from '@core/utils/filter'
import {
mdiDeleteOutline, mdiDotsVertical, mdiPencil, mdiPencilOutline, mdiPlus, mdiRestore,
} from '@mdi/js'
import { computed, onMounted, ref } from '@vue/composition-api'

import { deleteUser, getUsers, restoreUser } from '@/api/merchant/user'
import { useNotifyErrors } from '@/composables'
import { t } from '@/plugins/i18n'
import UserAside from '../../user-resource/UserAside.vue'

export default {
  name: 'MerchantUserList',

  components: { UserAside },

  filters: { date },

  props: {
    merchant: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {
    const aside = ref('')
    const users = ref([])
    const user = ref({})
    const ready = ref(false)

    const tableColumnHeaders = computed(() => [
      { text: 'ID', value: 'id', sortable: true },
      { text: 'NAME', value: 'profile.name', sortable: false },
      { text: 'EMAIL', value: 'email', sortable: false },
      { text: 'CREATED AT', value: 'created_at', sortable: false },
      { text: 'DELETED?', value: 'deleted_at', sortable: false },
      { text: 'ACTION', value: 'action', sortable: false },
    ])

    const refreshTableData = async () => {
      const response = await getUsers(props.merchant.id)

      if (response) {
        users.value = response
      }
    }

    const destroy = async user => {
      await useConfirm({
        title: 'Delete user',
        content: 'Are you sure?',
      })

      try {
        await deleteUser(props.merchant.id, user.id)
        refresh()
      } catch (error) {
        useNotifyErrors(error)
      }
    }

    const restore = async user => {
      await useConfirm({
        title: 'Restore user',
        content: 'Are you sure?',
      })

      try {
        await restoreUser(props.merchant.id, user.id)
        refresh()
      } catch (error) {
        useNotifyErrors(error)
      }
    }

    const closeAside = bol => {
      if (!bol) {
        user.value = null
        aside.value = null
      }
    }

    const toggleAside = slot => {
      user.value = slot
      aside.value = 'user'
    }

    const refresh = async () => {
      ready.value = false

      await refreshTableData()

      closeAside()

      ready.value = true
    }

    onMounted(async () => {
      await refreshTableData()
    })

    return {
      t,
      aside,
      ready,
      users,
      user,

      tableColumnHeaders,

      refresh,
      closeAside,
      toggleAside,

      destroy,
      restore,

      icons: {
        mdiPencil,
        mdiPlus,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiRestore,
      },
    }
  },
}
</script>

<style scoped>
.cross-out {
  text-decoration: line-through;
  opacity: 0.5;
}
</style>
