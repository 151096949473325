var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.aside === 'user')?_c('user-aside',{attrs:{"active":true,"merchant":_vm.merchant,"resource":_vm.user},on:{"changed":_vm.refresh,"aside":function (bol) { _vm.closeAside(bol); !bol && _vm.refresh() }}}):_vm._e(),_c('v-card',{staticClass:"mb-2"},[_c('v-card-title',[_c('v-spacer'),_c('v-btn',{staticClass:"mb-4 me-3",attrs:{"color":"primary"},on:{"click":function($event){return _vm.toggleAside(null, 'user')}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPlus))]),_c('span',[_vm._v("Add New User")])],1)],1),_c('v-data-table',{attrs:{"headers":_vm.tableColumnHeaders,"items":_vm.users,"sort-by":['id'],"sort-desc":[true],"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.profile.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_vm._v(" "+_vm._s(item.profile.name)+" ")])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_vm._v(" "+_vm._s(item.email)+" ")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_vm._v(" "+_vm._s(_vm._f("date")(item.created_at))+" ")])]}},{key:"item.deleted_at",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[(item.deleted_at)?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"error","text-color":"white"}},[_vm._v(" Yes ")]):_vm._e()],1)]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}],null,true)},[(item.deleted_at == null)?_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.toggleAside(item, 'user')}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")]),_c('span',[_vm._v("Edit")])],1)],1),(item.deleted_at == null)?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.destroy(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")]),_c('span',[_vm._v("Delete")])],1)],1):_vm._e()],1):_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.restore(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiRestore)+" ")]),_c('span',[_vm._v("Restore")])],1)],1)],1)],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }